import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
  static targets = ['collapsible', 'panelHeader', 'panelTitle', 'panelBody']

  collapseStatusKey = 'collapseStatus';

  connect () {
      $(this.element).addClass('without-animation')
      const collapseStatus = this.getCollapseStatus()
      if (collapseStatus === '1') {
          this.collapsePanel()
      }
      this.updateTooltip()
  }

  togglePanel = (e) => {
      if (e) {
          this.handleEvent(e)
      }
      $(this.element).removeClass('without-animation').toggleClass('collapsed')
      this.saveToLocalStorage()
      this.updateTooltip()
  }

  collapsePanel () {
      $(this.element).addClass('collapsed')
  }

  updateTooltip () {
      const $toggle = $('.toggle-panel')
      const newTitle = this.isCollapsed() ? $toggle.attr('data-title-expand') : $toggle.attr('data-title-collapse')
      $toggle.tooltip('dispose').tooltip({ title: newTitle }).tooltip('update')
  }

  handleEvent = (e) => {
      e.preventDefault()
      e.stopPropagation()
  }

  isCollapsed () {
      return $(this.element).hasClass('collapsed')
  }

  saveToLocalStorage () {
      localStorage.setItem(this.collapseStatusKey, this.isCollapsed() ? '1' : '0')
  }

  getCollapseStatus () {
      return localStorage.getItem(this.collapseStatusKey)
  }
}
