import { Controller } from 'stimulus'
import $ from 'jquery'

/**
 * Checkbox groups behavior
 *
 * Allows to check/uncheck a group of checkboxes using a single checkbox
 *
 * Required markup:
 *
 * ```html
 * <any-tag data-controller="chbx-group">
 *  <label><input type="checkbox" data-toggles-group="group1"> This checkbox toggles those that have matching `data-checkbox-group` attribute</label>
 *  <label><input type="checkbox" data-checkbox-group="group1" ...> One</label>
 *  <label><input type="checkbox" data-checkbox-group="group1" ...> Two</label>
 *  <label><input type="checkbox" data-toggles-group="group2"> This checkbox only toggles this next group</label>
 *  <label><input type="checkbox" data-checkbox-group="group2" ...> Three</label>
 *  <label><input type="checkbox" data-checkbox-group="group2" ...> Four</label>
 * </any-tag>
 * ```
 *
 * @attribute data-controller="chbx-group" sets the context for the behavior; similarly named groups will not toggle together if they are in different contexts (use this if you want to place two similar, but independent forms or checkbox groups onto a single page); a few different groups are supported within one context (like in the example above);
 * @attribute data-toggles-group="groupName" makes this checkbox a toggler for a named group; multiple groups may be specified within one attribute (separated by spaces); multiple togglers for one group are supported, they will all retain unified checked state among them;
 * @attribute data-checkbox-group="groupName" puts this checkbox into a group that follows it's toggler; multiple groups may be specified within one attribute (separated by spaces); a single checkbox may belong to a group and be a group toggler at the same time (e.g. in the tree-like structures) - just use both attributes with corresponding values in them; toggler state will reflect individual states across its group - if all checkboxes in a group are checked by the user, the toggler will be checked too, same for unchecked, and if only some checkboxes are checked by the user, the toggler will be in unchecked indeterminate state (usually displays as a [-] in user agents);
 *
 * Adding new checkboxes dynamically into any groups in any role is supported - simply add necessary elements into the enclosing context element. The state of dynamically added checkboxes stays pristine until next user interaction (you should add them in correct checked state). Adding new / removing existing groups from any data attribute dynamically is also supported, but checked state stays unchanged until next user interaction. Alternatively, triggering a "change" event on a toggler will propagate its state to its group after any changes made via scripting.
 */

const leaderPropName = 'toggles-group'
const followerPropName = 'checkbox-group'

export default class extends Controller {
    connect () {
        this.$context = $(this.element)

        this.$context
            .on('change.chbxGroup', this.leadersSelector(), (event, fromGroup) => {
                // when a leading checkbox is changed: change all followers accordingly
                const $leader = $(event.target)
                const state = $leader.prop('checked')
                const groupName = $leader.data(leaderPropName)
                if (fromGroup === groupName) {
                    // skip our own event when it comes from followers
                    return
                }

                this.$followers(groupName).prop('checked', state).trigger('change', groupName)
                // set the state of other leaders of the same group
                this.$leaders(groupName).not($leader).prop({
                    checked: state,
                    indeterminate: false
                }).trigger('change', groupName)
            })
            .on('change.chbxGroup', this.followersSelector(), (event, fromGroup) => {
                // when a following checkbox is changed
                const $thisFollower = $(event.target)
                const groupName = $thisFollower.data(followerPropName)
                if (fromGroup === groupName) {
                    // skip our own event when it comes from siblings
                    return
                }
                this.inferLeadersState(groupName)
            })
        for (const eachGroup of this.allKnownGroupNames()) {
            this.inferLeadersState(eachGroup)
        }
    }

    disconnect () {
        this.$context.off('.chbxGroup')
    }

    exactGroupMatcher (groupName) {
        return groupName ? `~="${groupName}"` : ''
    }

    leadersSelector (groupName) {
        return `[data-${leaderPropName}${this.exactGroupMatcher(groupName)}]`
    }

    $leaders (groupName) {
        return this.$context.find(this.leadersSelector(groupName))
    }

    followersSelector (groupName) {
        return `[data-${followerPropName}${this.exactGroupMatcher(groupName)}]`
    }

    $followers (groupName) {
        return this.$context.find(this.followersSelector(groupName))
    }

    inferLeadersState (groupName) {
        const $siblingFollowers = this.$followers(groupName)
        const targetState = $siblingFollowers.first().prop('checked')

        // compare the state of all the sibling follower checkboxes
        let allMatch = true
        $siblingFollowers.each(function (_, el) {
            const $checkbox = $(el)
            allMatch = (
                (targetState === $checkbox.prop('checked')) &&
                $checkbox.prop('indeterminate') === false
            )
            return allMatch
        })

        this.$leaders(groupName).prop({
            // if that state is the same for all, change the leading checkboxes state accordingly
            checked: allMatch ? targetState : false,
            // if it is different across all followers, change the leading checkboxes state to indeterminate
            indeterminate: !allMatch
        }).trigger('change', groupName)
    }

    allKnownGroupNames () {
        const allGroups = {}
        this.$leaders().each((_, element) => {
            const groupNames = $(element).data(leaderPropName).split(' ')
            for (const eachName of groupNames) {
                allGroups[eachName] = true
            }
        })
        return Object.keys(allGroups)
    }
}
