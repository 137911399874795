import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    static targets = ['relationshipType', 'physicianType', 'type', 'powerOfAttorney', 'legalAdvisor']

    connect () {
        this.check()
    }

    check () {
        const selected = this.typeTarget.value
        const familySelected = selected === 'family'
        $(this.relationshipTypeTarget).toggle(familySelected)
        $(this.physicianTypeTarget).toggle(selected === 'physician')
        $(this.legalAdvisorTarget).attr('disabled', !familySelected)
        $(this.powerOfAttorneyTarget).attr('disabled', !familySelected)
        if (!familySelected) {
            $(this.legalAdvisorTarget).get(0).checked = false
            $(this.powerOfAttorneyTarget).get(0).checked = false
        }
    }
}
