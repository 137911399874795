import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    static targets = []

    connect () {
        $(this.element).find('select[name*="2i"]').on('change', this.dateSelectHandler)
        $(this.element).find('select[name*="1i"]').on('change', this.dateSelectHandler)
    }

    dateSelectHandler = (e) => {
        const yearEl = this.getYearSelect(e.target.name)
        const monthEl = this.getMonthSelect(e.target.name)
        const dayEl = this.getDaySelect(e.target.name)
        const date = new Date(yearEl.value, parseInt(monthEl.value, 10) - 1, 40)
        const days = 40 - date.getDate()
        $(dayEl).find('option').each((index, option) => {
            $(option).attr('disabled', parseInt(option.value, 10) > days)
        })
        if (parseInt(dayEl.value, 10) > days) {
            dayEl.value = days
        }
    };

    getYearSelect (name) {
        return this.element.querySelector(`[name="${name.replace(/\di/, '1i')}"]`)
    }

    getMonthSelect (name) {
        return this.element.querySelector(`[name="${name.replace(/\di/, '2i')}"]`)
    }

    getDaySelect (name) {
        return this.element.querySelector(`[name="${name.replace(/\di/, '3i')}"]`)
    }
}
