import { Controller } from 'stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
    static targets = ['list']

    onChange (e) {
        const term = e.target.value.toLowerCase().trim()
        Turbo.visit(this.element.getAttribute('data-search-url').replace('__TERM__', term), {
            action: 'nothing',
            frame: 'diagnosesTable'
        })
    }
}
