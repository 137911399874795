import { Controller } from 'stimulus'

export default class extends Controller {
    connect () {
        // delay the binding after all other controllers are bound
        for (const formElement of this.element.elements) {
            if (/search/.test(formElement.type)) {
                formElement.addEventListener('input', this.search)
            } else {
                formElement.addEventListener('change', this.handleChange)
                formElement.addEventListener('search', this.handleChange)
            }
        }
    }

    disconnect () {
        for (const formElement of this.element.elements) {
            if (/search/.test(formElement.type)) {
                formElement.removeEventListener('input', this.search)
            } else {
                formElement.removeEventListener('change', this.handleChange)
                formElement.removeEventListener('search', this.handleChange)
            }
        }
    }

    search = () => {
        clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
            this.handleChange()
        }, 50)
    }

    handleChange = () => {
        const query = new URLSearchParams(new FormData(this.element)).toString()
        const url = `${window.location.pathname}?${query}`
        // this will ensure the replaceState works with Turbolinks!
        window.history.replaceState({ turbo: true, url }, '', url)
        this.element.requestSubmit()
    }
}
