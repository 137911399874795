import { Controller } from 'stimulus'

export default class extends Controller {
    connect () {
        this.element.addEventListener('submit', this.handleSubmit)
    }

    handleSubmit = (event) => {
        const valueOver10 = Array.from(this.element.elements).find((input) => {
            const originalValue = parseFloat(input.getAttribute('data-default-value'))
            if (originalValue) {
                const newValue = input.value
                return Math.abs(((newValue - originalValue) / originalValue) * 100) >= 10
            }
            return false
        })
        if (valueOver10) {
            window.alert(I18n.t('controllers.monitoring_goal.info_message'))
        }
    }

    disconnect () {
        this.element.removeEventListener('submit', this.handleSubmit)
    }
}
