import morphdom from 'morphdom'
import $ from 'jquery'

const shouldUpdateElement = (el, force, forceElementsWithDataAttribute) => {
    if (forceElementsWithDataAttribute && el.hasAttribute(`data-${forceElementsWithDataAttribute}`)) {
        return true
    }
    if (el.hasAttribute('data-permanent')) {
        return false
    }

    return force || !(el.classList.contains('dropdown') ||
        el.classList.contains('tab-pane') ||
        el.classList.contains('tab-item') ||
        el.getAttribute('data-toggle') === 'tab' ||
        el.nodeName === 'FORM')
}

export const updateDom = async (elementId, html, stimulusContext = null, options = {}) => {
    const parser = new DOMParser()
    const force = options.force || false
    const forceElementsWithDataAttribute = options.forceElementsWithDataAttribute || null
    const doc = await parser.parseFromString(html, 'text/html')
    const content = doc.getElementById(elementId)

    morphdom(window.document.getElementById(elementId), content, {
        onBeforeElUpdated: (fromEl) => {
            const shouldUpdate = shouldUpdateElement(fromEl, force, forceElementsWithDataAttribute)
            if (shouldUpdate) {
                const controllerAttr = fromEl.getAttribute('data-controller')
                if (controllerAttr && stimulusContext) {
                    controllerAttr.split(' ').forEach((controllerName) => {
                        const controller = stimulusContext.module.application.getControllerForElementAndIdentifier(fromEl, controllerName)
                        controller.disconnect()
                    })
                }
                if (fromEl.getAttribute('data-toggle') === 'tooltip') {
                    $(fromEl).tooltip('dispose')
                }
            }
            return shouldUpdate
        },
        onElUpdated: (el) => {
            const shouldUpdate = shouldUpdateElement(el, force, forceElementsWithDataAttribute)
            const controllerAttr = el.getAttribute('data-controller')
            if (shouldUpdate && controllerAttr && stimulusContext) {
                controllerAttr.split(' ').forEach((controllerName) => {
                    const controller = stimulusContext.module.application.getControllerForElementAndIdentifier(el, controllerName)
                    controller.connect()
                })
            }
        }
    })
    $('[data-toggle=tooltip]').tooltip()
}
