import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['description', 'category', 'files']
    static values = {
        patient: String
    }

    connect () {
        this.element.querySelector("input[type='submit']").addEventListener('click', this.handleSubmit)
    }

    handleSubmit = (event) => {
        const category = this.categoryTarget.querySelector(`option[value="${this.categoryTarget.value}"]`).textContent
        const description = this.descriptionTarget.value
        const fileNames = Array.from(this.filesTarget.files).map((filename) => `"${filename.name}"`).join(', ')

        if (category && description && fileNames && this.patientValue) {
            const confirmed = window.confirm(I18n.t('controllers.document_form.confirm_message', {
                category,
                patient_name: this.patientValue,
                description,
                filenames: fileNames
            }))
            if (!confirmed) {
                event.preventDefault()
            }
        }
    }
}
