import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    static targets = ['checkAll', 'checkbox', 'actionButton']

    connect () {
        for (let i = 0; i < this.actionButtonTargets.length; i += 1) {
            this.actionButtonTargets[i].addEventListener('click', this.handleSubmit)
        }
        for (let i = 0; i < this.checkboxTargets.length; i += 1) {
            this.checkboxTargets[i].addEventListener('change', this.checkSelection)
            this.checkboxTargets[i].addEventListener('change', this.checkAllSelection)
        }
        this.checkAllTarget.addEventListener('change', this.checkAllSelection)
        setTimeout(() => this.checkSelection(), 1)
    }

    disconnect () {
        for (let i = 0; i < this.actionButtonTargets.length; i += 1) {
            this.actionButtonTargets[i].removeEventListener('click', this.handleSubmit)
        }
        for (let i = 0; i < this.checkboxTargets.length; i += 1) {
            this.checkboxTargets[i].removeEventListener('change', this.checkSelection)
            this.checkboxTargets[i].removeEventListener('change', this.checkAllSelection)
        }
        this.checkAllTarget.removeEventListener('change', this.checkAllSelection)
    }

    selectAllCheckboxes (event) {
        for (let i = 0; i < this.checkboxTargets.length; i += 1) {
            this.checkboxTargets[i].checked = event.target.checked
        }
        this.checkSelection()
    }

    checkAllSelection = () => {
        const isChecked = (target) => target.checked
        this.checkAllTarget.checked = this.checkboxTargets.every(isChecked)
    }

    checkSelection = () => {
        const selectedCount = this.checkboxTargets.filter(node => node.checked).length
        if (selectedCount > 0) {
            $(this.actionButtonTargets).removeAttr('disabled')
        } else {
            $(this.actionButtonTargets).attr('disabled', 'disabled')
        }
    }

    handleSubmit = (e) => {
        e.preventDefault()
        const form = $('<form></form>').get(0)
        $(this.element).append(form)

        const ids = this.checkboxTargets.filter(el => el.checked).map(el => el.value)
        form.action = e.currentTarget.dataset.url + '?ids=' + ids.join(',')
        form.method = 'POST'
        form.requestSubmit()

        $(this.element).remove(form)
    }
}
