import { Application } from 'stimulus'
import Appsignal from '@appsignal/javascript'
import { installErrorHandler } from '@appsignal/stimulus'
import controllers from '../controllers/*_controller.js'
import componentControllers from '../../components/**/component_controller.js'
import '../../components/**/component.scss'

export function loadApplication () {
    const application = Application.start()
    const metaElement = document.querySelector("meta[name='appsignal-frontend-key']")
    if (metaElement) {
        const key = metaElement.getAttribute('content')
        if (key) {
            const appsignal = new Appsignal({
                key,
                revision: metaElement.getAttribute('data-revision')
            })
            installErrorHandler(appsignal, application)
        }
    }

    const getControllerName = (controller) => {
        const names = controller.name.replace(/\.+--/, '').split('--')
        names.shift()
        return names.join('--')
    }

    controllers.forEach((controller) => {
        application.register(getControllerName(controller), controller.module.default)
    })

    componentControllers.forEach((controller) => {
        application.register(getControllerName(controller), controller.module.default)
    })

    return application
}
