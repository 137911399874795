import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['category', 'survey'];

    categoryChange () {
        const selected = this.categoryTarget.value
        if (selected !== 'questionnaire') {
            this.surveyTarget.value = ''
        }
    }

    surveyChange () {
        const selected = this.surveyTarget.value
        if (selected) {
            this.categoryTarget.value = 'questionnaire'
        }
    }
}
