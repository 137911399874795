import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['title', 'firstname', 'surname', 'text', 'role', 'tmcName']

    connect () {
        this.textEdited = !!this.textTarget.value
        this.checkRole()
    }

    checkRole () {
        if (!this.textEdited) {
            const role = this.roleTarget.value
            let nameParts = []
            if (role === 'telemedicine_center') {
                if (this.hasTmcNameTarget) {
                    nameParts.push(this.tmcNameTarget.value.trim())
                }
            } else {
                if (this.hasTitleTarget) {
                    nameParts.push(this.titleTarget.value.trim())
                }
                if (this.hasFirstnameTarget) {
                    nameParts.push(this.firstnameTarget.value.trim())
                }
                if (this.hasSurnameTarget) {
                    nameParts.push(this.surnameTarget.value.trim())
                }
            }
            let text = this.textTarget.getAttribute('data-invitation')
            nameParts = nameParts.filter(v => v.length > 0)
            if (nameParts.length > 0) {
                text = text.replaceAll('{NAME}', nameParts.join(' '))
            }
            this.textTarget.value = text
        }
    }

    setEdited () {
        this.textEdited = true
    }
}
