import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    static targets = ['surveys', 'type']

    connect () {
        this.check()
    }

    check () {
        const selected = this.typeTarget.value
        $(this.surveysTarget).toggle(selected === 'survey')
        $('.next-measurements').hide()
        $(`.next-measurements-${selected}`).show()
    }
}
