import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['message', 'previewMax', 'previewMarie']
    static values = {
        url: String,
        error: String
    }

    messageBox = null
    lastRenderedText = null

    connect () {
        this.messageBox = this.messageTarget
    }

    messageChanged () {
        // user deleted the first char of a snippet
        const regex = /{{[a-zA-Z/]+}$/

        const caretPos = this.messageBox.selectionEnd

        const oldText = this.messageBox.value
        const newText = oldText.substring(0, caretPos).replace(regex, '') + oldText.substring(caretPos)

        if (newText !== oldText) {
            this.messageBox.value = newText
            this.messageBox.selectionStart = caretPos - (oldText.length - newText.length)
            this.messageBox.selectionEnd = caretPos - (oldText.length - newText.length)
        }
    }

    pasteSnippet (event) {
        const value = event.currentTarget.getAttribute('data-value')
        const insertion = `{{${value}}}`

        const caretPos = this.messageBox.selectionEnd

        let text = this.messageBox.value
        text = text.substring(0, caretPos) + insertion + text.substring(caretPos)
        this.messageBox.value = text

        this.messageBox.selectionStart = caretPos + insertion.length
        this.messageBox.selectionEnd = caretPos + insertion.length
        this.messageBox.focus()
    }

    async showPreview (event) {
        this.fixTabs(event)
        if (this.lastRenderedText && this.lastRenderedText === this.messageBox.value) {
            return
        }

        [this.previewMaxTarget.value, this.previewMarieTarget.value] = await Promise.all([
            this._generatePreviewMessage('Max', 'Mustermann', null, 'male'),
            this._generatePreviewMessage('Marie', 'Musterfrau', 'Prof. Dr.', 'female')
        ])
    }

    fixTabs (event) {
        if (event.currentTarget.classList.contains('editor')) {
            document.querySelector('button.preview').classList.remove('active')
        }
        if (event.currentTarget.classList.contains('preview')) {
            document.querySelector('button.editor').classList.remove('active')
        }
    }

    async _generatePreviewMessage (firstname, surname, title, gender) {
        const text = this.messageBox.value
        const body = JSON.stringify({
            learning_message: {
                text, firstname, surname, title, gender
            }
        })

        const res = await fetch(this.urlValue, {
            method: 'POST',
            credentials: 'include',
            headers: {
                'Content-Type': 'application/json'
            },
            body
        })

        if (res.status !== 200) {
            return this.errorValue
        }

        const json = await res.json()
        if (json.learning_message !== null) {
            this.lastRenderedText = text
            return json.learning_message
        } else {
            return this.errorValue
        }
    }
}
