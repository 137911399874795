import $ from 'jquery'
import '../src/care_center.scss'
import { loadApplication } from '../helpers/pack_helper'
import InvitationController from '../controllers/invitation_edit_form_controller'
import ListController from '../controllers/list_controller'
import '@hotwired/turbo-rails'

const application = loadApplication()
application.register('invitation-edit-form', InvitationController)
application.register('list', ListController)

$(document).on('ajax:success ajax:error', 'form', (event) => {
    const [newDocument] = event.detail
    if (newDocument.contentType === 'text/html') {
        document.body.innerHTML = newDocument.body.innerHTML
    }
})
