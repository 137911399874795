import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    static targets = []

    connect () {
        $(this.element).addClass('custom-file-input').on('change', this.handleChange)
    }

    handleChange = (e) => {
        const { files } = e.target
        let text = ''
        if (files.length === 1) {
            text = files.item(0).name
        } else if (files.length > 1) {
            text = I18n.t('controllers.file_input.files_chosen', { count: files.length })
        }
        $(e.target).next().html(text)
    };
}
