import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['template', 'container']

    onDelete (e) {
        e.preventDefault()
        this.containerTarget.childNodes.forEach((node) => {
            if (node.contains(e.currentTarget)) {
                this.containerTarget.removeChild(node)
            }
        })
    }

    onAdd (e) {
        e.preventDefault()
        this.containerTarget.appendChild(this.templateTarget.content.cloneNode(true))
    }
}
