import { Controller } from 'stimulus'
import { updateDom } from '../src/utils'

// Override fetch to count number of open requests
(function (window, fetch) {
    window.fetchActive = 0
    const inc = () => {
        window.fetchActive++
    }
    const dec = () => {
        window.fetchActive--
    }

    window.fetch = function () {
        const req = fetch.apply(window, arguments)

        inc()
        req.then(dec, dec)

        return req
    }
}(window, fetch))

export default class extends Controller {
    connect () {
        if (!this.element.id) {
            throw new Error('Element must have an ID')
        }
        if (window.sessionStorage.getItem('webportal__disable_auto_refresh')) {
            return
        }
        this.timeout = 15000
        this.autoRefresh = true
        this.timeoutId = null
        this.triggerTimeout()
    }

    disconnect () {
        this.autoRefresh = false
        if (this.timeoutId) {
            clearTimeout(this.timeoutId)
        }
    }

    triggerTimeout () {
        this.timeoutId = setTimeout(this.refreshFrame, this.timeout)
    }

    refreshFrame = async () => {
        // skip call if there are active requests
        if (window.fetchActive > 0) {
            console.log('Skipped refresh because of remaining requests')
            this.triggerTimeout()
            return
        }
        const currentLocation = window.location.href
        let redirectUrl = false
        const html = await fetch(currentLocation, { credentials: 'include', headers: { Prefer: 'skip-session-tracking' } }).then((response) => {
            if (response.url !== currentLocation) {
                redirectUrl = response.url
            }
            if (response.ok) {
                return response.text()
            }
            throw new Error(response.status)
        })
        if (redirectUrl) {
            window.location.href = redirectUrl
        } else if (currentLocation === window.location.href && this.autoRefresh) {
            updateDom(this.element.id, html, this.context)
        }
    }
}
