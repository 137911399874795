import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    connect () {
        this.enableIncidentList()
    }

  enableIncidentList = () => {
      const radioNodeList = this.element.querySelectorAll('input[name="resolve_incident[resolve]"]')

      radioNodeList.forEach((radio) => {
          const currentNode = $(`${radio.getAttribute('data-target')}`)
          if (radio.checked) {
              currentNode.show(300)
          } else {
              currentNode.hide()
          }
      })
  }
}
