import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    static targets = []

    connect () {
        $(this.element).on('click', (e) => {
            e.preventDefault()
            const target = e.currentTarget.getAttribute('data-target')
            $(target).val('')
            $(target).trigger('change')
        })
    }
}
