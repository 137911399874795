import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    static targets = ['disable']

    connect () {
        const exception = this.disableTargets.filter(disableTarget =>
            $(disableTarget).find("input[type='radio']:checked").length === 1
        )
        this.disableAllTargetsExceptOne(exception)
    }

    toggleActiveOptions (event) {
        this.disableAllTargetsExceptOne(event.target)
    }

    disableAllTargetsExceptOne (exceptionTarget) {
        this.disableTargets.forEach((disableTarget) => {
            $(disableTarget).find("input[type!='radio']").prop('disabled', true)
        })
        $(exceptionTarget).closest("[data-medication-form-target='disable']").find('input').prop('disabled', false)
    }
}
