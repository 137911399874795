import { Controller } from 'stimulus'
import $ from 'jquery'
import 'daterangepicker'
import moment from 'moment'

const dateRangePickers = []
document.addEventListener('scroll', (e) => {
    dateRangePickers.forEach((controller) => controller.onScroll(e))
}, true)
export default class extends Controller {
    static targets = []

    static values = {
        maxSpan: String, // set max span e.g. 7_days, 14_months, 1_week
        minDate: String,
        maxDate: String,
        timePicker: Boolean, // shows time picker
        autoSubmit: Boolean, // if true the form will be submitted on apply
        showDropdowns: Boolean, // shows dropdowns to switch months
        dateRange: Boolean, // if true the date picker runs in date range mode, the value must be a date range "2022-10-10..2022-12-10"
        insertBefore: Boolean // if true the date input field will be added before the input element
    }

    dateInputField
    dateRangePicker
    dateSeparator = '..'
    displayFormat = 'DD.MM.YYYY'
    displayTimeFormat = 'DD.MM.YYYY HH:mm'

    connect () {
        dateRangePickers.push(this)
        this.element.autocomplete = 'off'
        this.locale = {
            format: this.timePickerValue === true ? this.displayTimeFormat : this.displayFormat,
            applyLabel: I18n.t('controllers.date_picker.apply'),
            cancelLabel: I18n.t('controllers.date_picker.cancel')
        }
        // create new input for date picker
        const clone = this.element.cloneNode()
        Object.keys(clone.dataset).forEach(dataKey => {
            if (dataKey.startsWith('datePicker')) {
                delete clone.dataset[dataKey]
            }
        })
        delete clone.dataset.controller
        clone.name = ''
        clone.type = 'text'
        clone.value = ''
        clone.autocomplete = 'off'
        this.dateInputField = $(clone)
        if (this.element.id) {
            this.element.id = ''
        }
        // and append or prepend the element
        if (this.insertBeforeValue) {
            $(this.element).before(this.dateInputField)
        } else {
            $(this.element).after(this.dateInputField)
        }
        $(this.element).attr('type', 'hidden')
        this.dateInputField.on('change', this.updateHiddenInput)
        this.initDatePicker()
    }

    initDatePicker () {
        if (this.element.value) {
            if (this.dateRangeValue) {
                const [startDate, endDate] = this.element.value.split(this.dateSeparator)
                this.setStartAndEndDate(startDate, endDate)
            } else {
                this.dateInputField.val(moment(this.element.value).format(this.locale.format))
            }
        }
        this.dateInputField.daterangepicker(this.getDatePickerConfig())
        this.dateInputField.on('apply.daterangepicker', this.handleApply)
        this.dateRangePicker = this.dateInputField.data('daterangepicker')
    }

    onScroll = (e) => {
        if (e.currentTarget.contains(this.element)) {
            this.dateRangePicker.move()
        }
    }

    getDatePickerConfig () {
        let maxSpan
        if (this.maxSpanValue) {
            const [amount, unit] = this.maxSpanValue.split('_')
            maxSpan = {
                [unit]: amount
            }
        }
        return {
            singleDatePicker: !this.dateRangeValue,
            showDropdowns: this.showDropdownsValue || false,
            locale: this.locale,
            minDate: this.minDateValue ? moment(this.minDateValue) : false,
            maxDate: this.maxDateValue ? moment(this.maxDateValue) : false,
            timePicker: this.timePickerValue,
            timePicker24Hour: true,
            startDate: this.startDate,
            autoUpdateInput: false,
            maxSpan
        }
    }

    get startDate () {
        if (this.dateRangeValue) {
            return undefined
        } else {
            return this.element.value ? moment(this.element.value) : new Date()
        }
    }

    handleApply = () => {
        this.updateDateInput()
        // after this we need to trigger on change
        this.dateInputField.trigger('change')
        if (this.autoSubmitValue) {
            this.element.form.requestSubmit()
        }
    }

    updateDateInput () {
        if (this.dateRangeValue) {
            this.setStartAndEndDate(this.dateRangePicker.startDate, this.dateRangePicker.endDate)
        } else {
            this.dateInputField.val(this.dateRangePicker.startDate.format(this.locale.format))
        }
    }

    setStartAndEndDate (startDate, endDate) {
        this.dateInputField.val(`${moment(startDate).format(this.displayFormat)} - ${moment(endDate).format(this.displayFormat)}`)
    }

    updateHiddenInput = () => {
        if (!this.dateRangePicker) {
            return
        }
        const valueBefore = this.element.value
        if (this.dateRangeValue) {
            if (this.dateInputField.val()) {
                const [startDate, endDate] = this.dateInputField.val().split(' - ')
                $(this.element).val(moment(startDate, this.locale.format).format('YYYY-MM-DD') + this.dateSeparator + moment(endDate, this.locale.format).format('YYYY-MM-DD'))
            } else {
                $(this.element).val('')
            }
        } else {
            const momentDate = moment(this.dateInputField.val(), this.locale.format)
            this.dateInputField.get(0).setCustomValidity('')
            if (momentDate.isValid()) {
                const dateValue = this.timePickerValue ? momentDate.toISOString() : momentDate.format('YYYY-MM-DD')
                $(this.element).val(dateValue)
            } else {
                if (this.dateInputField.val()) {
                    this.dateInputField.get(0).setCustomValidity(I18n.t('controllers.date_picker.invalid_date'))
                }
                $(this.element).val(this.element.value)
            }
        }
        if (valueBefore !== this.element.value) {
            if ('createEvent' in document) {
                const evt = document.createEvent('HTMLEvents')
                evt.initEvent('change', false, true)
                this.element.dispatchEvent(evt)
            } else {
                this.element.fireEvent('onchange')
            }
        }
    }

    disconnect () {
        const index = dateRangePickers.indexOf(this)
        if (index > -1) {
            dateRangePickers.splice(index, 1)
        }
        if (this.dateRangePicker) {
            if (this.dateInputField.attr('id')) {
                this.element.id = this.dateInputField.attr('id')
                this.element.type = 'text'
            }
            this.dateRangePicker.remove()
            this.dateInputField.remove()
        }
    }
}
