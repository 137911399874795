import { Controller } from 'stimulus'
import { Turbo } from '@hotwired/turbo-rails'

export default class extends Controller {
    connect () {
        document.addEventListener('turbo:before-frame-render', this.syncForm)
    }

    updateForm (params = {}) {
        const frame = this.element.closest('turbo-frame')
        if (frame) {
            this.frameId = frame.id
            let dataUrl = this.element.getAttribute('data-url')
            if (!dataUrl) {
                const path = this.element.getAttribute('action')
                dataUrl = `${window.location.protocol}//${window.location.host}${path}`
            }
            const url = new URL(dataUrl)
            const form = this.element
            const formParams = Array.from(
                new FormData(form)
            )
            formParams.forEach(([key, value]) => {
                url.searchParams.append(key, value)
            })
            Object.keys(params).forEach((k) => {
                url.searchParams.append(k, params[k])
            })
            Turbo.visit(url, {
                action: 'nothing',
                frame: this.frameId
            })
        }
    }

    syncForm = (e) => {
        const newFrame = e.detail.newFrame
        if (newFrame && newFrame.id === this.frameId) {
            const elements = newFrame.querySelectorAll('input,textarea,select')
            for (const node of elements) {
                if (node.id) {
                    if (node.getAttribute('data-force-update') === 'true') {
                        continue
                    }
                    const element = e.target.querySelector(`#${node.id}`)
                    if (element) {
                        if (node.type === 'checkbox' || node.type === 'radio') {
                            node.checked = element.checked
                        } else {
                            node.value = element.value
                        }
                    }
                }
            }
        }
    }

    disconnect () {
        document.removeEventListener('turbo:before-frame-render', this.syncForm)
    }
}
