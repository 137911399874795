import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['dropdown']
    connect () {
        if (window.sessionStorage.getItem('webportal__disable_auto_refresh')) {
            return
        }
        this.startTimeout()
    }

    disconnect () {
        clearTimeout(this.timeout)
    }

    startTimeout () {
        this.timeout = setTimeout(() => {
            this.refreshFrame()
        }, 10000)
    }

    refreshFrame () {
        if (!this.dropdownTarget.classList.contains('show')) {
            this.element.querySelector('#user_notifications').reload()
        }
        this.startTimeout()
    }
}
