import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['submit']

    connect () {
        this.checkForm()
    }

    checkForm () {
        this.submitTarget.disabled = !!Array.from(this.element.elements)
            .find((node) => node.required && (node.value === '' || (!node.checked && node.type === 'checkbox')))
    }
}
