import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    togglePassword (event) {
        const passwordField = $(this.element).find('input')[0]
        const icon = $(this.element).find('i')[0]

        if (passwordField.type === 'password') {
            icon.classList.remove('fa-eye')
            icon.classList.add('fa-eye-slash')
            passwordField.type = 'text'
        } else {
            icon.classList.remove('fa-eye-slash')
            icon.classList.add('fa-eye')
            passwordField.type = 'password'
        }
    }
}
