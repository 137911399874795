import $ from 'jquery'
import AutocompleteController from './autocomplete_controller'

export default class extends AutocompleteController {
    static targets = AutocompleteController.targets.concat(['badges', 'select'])

    connect () {
        super.connect()
        this.selectedOptions = []
        $(this.badgesTarget).delegate('span button', 'click', this.handleRemoveButtonClick)
        $(this.element).on('click', this.forwardClickToInput)
        $(this.fieldTarget).bind('keydown', this.handleKeyPress)
        $(this.selectTarget).children('option[selected]').each((_, child) => {
            this.selectedOptions.push({
                id: child.value,
                name: child.textContent
            })
        })
        this.renderSelectedOptions()
    }

    disconnect () {
        super.disconnect()
        $(this.element).unbind('click', this.forwardClickToInput)
        $(this.fieldTarget).unbind('keydown', this.handleKeyPress)
        $(this.badgesTarget).undelegate('span button', 'click', this.handleRemoveButtonClick)
    }

    get optionIds () {
        return $(this.selectTarget).children().map((_, child) => child.value).toArray()
    }

    get availableOptions () {
        return $(this.selectTarget).children().map((_, child) => ({
            id: child.value,
            name: child.textContent,
            selected: child.selected
        })).toArray().filter((option) => !option.selected)
    }

    get autocompleteConfig () {
        return { ...super.autocompleteConfig, minLength: 0, autoselect: true }
    }

    source () {
        return (nameOrEmail, callback) => {
            let ret = this.availableOptions
            if (nameOrEmail) {
                const searchStrings = nameOrEmail.toLowerCase().split(/\s+/).map((str) => str.replace(/[,.]/gi, ''))
                ret = this.availableOptions.filter((option) => {
                    const ret = searchStrings.filter(str => option.name.toLowerCase().indexOf(str) > -1)
                    return ret.length === searchStrings.length
                })
            }
            callback(ret)
        }
    }

    handleSelect (event, suggestion) {
        event.preventDefault()
        if (!suggestion.id) {
            return
        }
        this.addOption(suggestion)
    }

    refreshAutoComplete () {
        // here we need to clear the cached suggestion to prevent duplicated in the autocomplete dropdown
        const autocomplete = this.ac.data('aaAutocomplete')
        autocomplete.dropdown.datasets[0].clearCachedSuggestions()
        autocomplete.dropdown.update('')
    }

    handleKeyPress = (event) => {
        if (event.currentTarget.value === '' && event.code === 'Backspace') {
            if (this.selectedOptions.length > 0) {
                this.removeOption(this.selectedOptions[this.selectedOptions.length - 1].id)
            }
        }
    }

    filterResults (results) {
        return results.filter(r => this.optionIds.indexOf(r.id.toString()) === -1)
    }

    handleRemoveButtonClick = (event) => {
        this.removeOption(event.currentTarget.getAttribute('data-value'))
    }

    forwardClickToInput = () => {
        this.fieldTarget.focus()
    }

    renderSelectedOptions () {
        $(this.badgesTarget).children().remove()
        this.selectedOptions.forEach((option) => {
            $(this.badgesTarget).append(`
                <span class="badge badge-primary mb-1">
                    <span>${option.name}</span>
                    <button class="btn btn-outline-light btn-sm" type="button" data-value="${option.id}" aria-label="remove" style="line-height: 1"><i class="fas fa-times"></i></button
                </span>
            `)
        })
        this.selectTarget.childNodes.forEach((child) => {
            child.selected = !!this.selectedOptions.find(option => option.id === child.value)
        })
    }

    addOption (option) {
        this.selectedOptions.push(option)
        this.renderSelectedOptions()
        this.refreshAutoComplete()
    }

    removeOption (id) {
        this.selectedOptions = this.selectedOptions.filter(option => option.id !== id)
        this.renderSelectedOptions()
        this.refreshAutoComplete()
    }
}
