import { Controller } from 'stimulus'
import $ from 'jquery'
// import * as Turbo from '@hotwired/turbo'

export default class extends Controller {
    connect () {
        $(this.element).modal('show')
        $(this.element).on('hidden.bs.modal', this.handleClose)
        this.element.setAttribute('data-turbo-frame', 'contentWrapper')
        document.addEventListener('turbo:submit-start', this.handleSubmitStart)
        document.addEventListener('turbo:submit-end', this.handleSubmit)
        document.addEventListener('turbo:before-fetch-response', this.beforeFetch)
    }

    beforeFetch = (event) => {
        if (this.formSubmission && event.detail.fetchResponse.succeeded && event.detail.fetchResponse.redirected) {
            // set the submitter to this element to change the target frame after a successful form submit
            // without this hack the modal is just closed and the main content is not refreshed!
            this.formSubmission.submitter = this.element
        }
    }

    handleSubmitStart = (event) => {
        this.formSubmission = event.detail.formSubmission
    }

    disconnect () {
        this.close()
        document.removeEventListener('turbo:submit-start', this.handleSubmitStart)
        document.removeEventListener('turbo:before-fetch-response', this.beforeFetch)
        document.removeEventListener('turbo:submit-end', this.handleSubmit)
    }

    handleClose = () => {
        // Remove the modal element so it doesn't blanket the screen
        $(this.element).remove()
    }

    close () {
        $(this.element).modal('hide')
    }

    handleSubmit = (e) => {
        if (e.detail.success) {
            this.close()
            if (window.location.href !== e.detail.fetchResponse.response.url) {
                window.history.pushState(null, document.title, e.detail.fetchResponse.response.url)
            }
        }
    }

    handleKeyup (e) {
        if (e.code === 'Escape') {
            this.close()
        }
    }
}
