import { Controller } from 'stimulus'
import Viewer from 'viewerjs'

export default class extends Controller {
    connect () {
        this.viewer = new Viewer(this.element, {
            inline: true,
            navbar: false,
            toolbar: {
                zoomIn: true,
                zoomOut: true,
                oneToOne: true,
                rotateLeft: true,
                rotateRight: true
            }
        })
        this.element.style.opacity = '0'
    }

    disconnect () {
        this.viewer.destroy()
    }
}
