import { Controller } from 'stimulus'
import autocomplete from 'autocomplete.js'
import { Turbo } from '@hotwired/turbo-rails'
import Mustache from 'mustache'

export default class extends Controller {
    static targets = ['field', 'suggestionTemplate', 'emptyTemplate'];

    source () {
        const url = this.data.get('url')
        return (nameOrEmail, callback) => {
            fetch(url.replace('__TERM__', encodeURIComponent(nameOrEmail)), {
                method: 'GET',
                headers: {
                    accept: 'application/json'
                }
            }).then(res => res.json()).then(callback)
        }
    }

    get autocompleteConfig () {
        return {
            hint: false,
            openOnFocus: true,
            clearOnSelected: true,
            debug: false,
            cssClasses: {
                noPrefix: true,
                suggestion: 'dropdown-item',
                cursor: 'active'
            }
        }
    }

    connect () {
        const suggestionTemplate = this.suggestionTemplateTarget.innerHTML
        const emptyTemplate = this.emptyTemplateTarget.innerHTML
        this.ac = autocomplete(this.fieldTarget, this.autocompleteConfig, [
            {
                source: this.source(),
                debounce: 200,
                templates: {
                    suggestion: (suggestion) => {
                        return Mustache.render(suggestionTemplate, suggestion)
                    },
                    empty: (option) => {
                        return Mustache.render(emptyTemplate, option)
                    }
                }
            }
        ]).on('autocomplete:selected', (event, suggestion) => {
            this.handleSelect(event, suggestion)
        })
        if (this.fieldTarget.autofocus) {
            this.fieldTarget.focus()
        }
        this.fieldTarget.setAttribute('autocomplete', 'chrome-off') // this is needed to really disable autocomplete in chrome!!
    }

    handleSelect (event, suggestion) {
        Turbo.visit(suggestion.href, { replace: true })
    }
}
