import { Controller } from 'stimulus'
import 'date-fns'
import de from 'date-fns/locale/de'
import {
    Chart,
    LinearScale,
    LineController,
    LineElement,
    PointElement,
    TimeScale, Tooltip
} from 'chart.js'
import 'chartjs-adapter-date-fns'

Chart.register(TimeScale, LinearScale, LineController, PointElement, LineElement, Tooltip)

const COLOR_MAP = {
    critical: '#A93800',
    fair: '#f6c23e',
    good: '#9AE89A'
}

const A_DAY = 24 * 60 * 60 * 1000

const baseConfig = () => ({
    type: 'line',
    data: {
        datasets: []
    },
    options: {
        interaction: {
            intersect: false,
            mode: 'index'
        },
        legend: {
            display: false
        },
        plugins: {},
        layout: {
            padding: {
                left: 0,
                right: 10,
                top: 0,
                bottom: 0
            }
        },
        scales: {
            x: {
                type: 'time',
                time: {
                    unit: 'day',
                    displayFormats: {
                        quarter: 'MMM YYYY'
                    },
                    tooltipFormat: "PP HH:mm 'Uhr",
                    parser: "yyyy-MM-dd'T'HH:mm:ssXXXXX"
                },
                title: {
                    display: false,
                    labelString: 'Date'
                },
                ticks: {
                    major: {
                        fontStyle: 'bold',
                        fontColor: '#FF0000'
                    }
                },
                adapters: {
                    date: {
                        locale: de
                    }
                }
            },
            y: {}
        },
        maintainAspectRatio: false
    }
})

export default class extends Controller {
    static targets = ['canvas'];

    static values = { config: Object };

    connect () {
        this.loadChart()
    }

    async loadChart () {
        if (this.configValue) {
            this.currentConfig = this.configValue

            const chartJSconfig = this.buildChartJSConfig(this.currentConfig)
            if (this.chart) {
                // eslint-disable-next-line no-underscore-dangle
                this.chart.config._config = chartJSconfig
                this.chart.update()
            } else {
                this.chart = new Chart(this.canvasTarget, chartJSconfig)
            }
        }
    }

    buildChartJSConfig (config) {
        const chartConfig = { ...baseConfig() }
        const datasets = this.buildDatasets(config)
        const values = config.datasets.map((d) => d.values.map(r => r.value))
        if (config.min_value) {
            values.push(parseFloat(config.min_value))
        }
        if (config.max_value) {
            values.push(parseFloat(config.max_value))
        }
        chartConfig.data.datasets = datasets
        const { unit } = this.currentConfig
        const tooltipLabel = (element) => {
            if (element.raw.label) {
                return element.raw.label
            }
            return `${element.parsed.y.toString().replace('.', ',')} ${unit}`
        }
        const min = Math.min(...values.flat())
        const max = Math.max(...values.flat())
        // decrease x.min by a day to prevent cutting of the dot
        chartConfig.options.scales.x.min = new Date(config.min_date).getTime() - A_DAY
        // extend x.max by 1.2 days to prevent cutting of the dot
        chartConfig.options.scales.x.max = new Date(config.max_date).getTime() + 1.2 * A_DAY
        chartConfig.options.scales.y.suggestedMin = min
        chartConfig.options.scales.y.suggestedMax = max
        if (config.y_axis_tick_configuration) {
            chartConfig.options.scales.y.ticks = config.y_axis_tick_configuration
        }

        if (config.y_axis_label_configuration) {
            chartConfig.options.scales.y.ticks.callback = (value, index, ticks) => {
                return config.y_axis_label_configuration[value]
            }
        }

        chartConfig.options.animations = false
        chartConfig.options.plugins.tooltip = {
            callbacks: {
                label: tooltipLabel
            }
        }
        return chartConfig
    }

    buildDatasets = (config) => {
        return config
            .datasets
            .map(
                (
                    dataset
                ) => ({
                    label: '',
                    lineTension: 0.1,
                    fill: false,
                    pointRadius: 5,
                    data: dataset.values.map((r) => {
                        return {
                            x: r.time,
                            y: r.value,
                            label: r.label
                        }
                    }),
                    backgroundColor: dataset.values.map(({ state }) => {
                        return COLOR_MAP[state] || '#a6b0c3'
                    })
                }))
    }
}
