import { Controller } from 'stimulus'
import $ from 'jquery'

export default class extends Controller {
    static targets = []

    connect () {
        if (!(this.element instanceof HTMLFormElement)) {
            throw new Error('Element must be of type form')
        }
        this.originalValues = {}
        this.submitButton = $(this.element).find('[type="submit"]').get(0)
        Array.from(this.element.elements).forEach((input) => {
            this.originalValues[input.name] = input.value
        })
        $(this.element).on('keyup', 'input, textarea', this.handleKeyUp)
        this.toggleSubmitButton(true)
    }

    handleKeyUp = (event) => {
        const node = event.currentTarget
        const originalValue = this.originalValues[node.name]
        this.toggleSubmitButton($(node).val().trim() === originalValue)
    }

    toggleSubmitButton (disable) {
        const $submitBtn = $(this.submitButton)
        if (disable) {
            $submitBtn.attr('disabled', 'disabled')
        } else {
            $submitBtn.removeAttr('disabled')
        }
    }
}
