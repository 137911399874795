import { Controller } from 'stimulus'
import Sortable, { AutoScroll } from 'sortablejs/modular/sortable.core.esm.js'
import $ from 'jquery'

Sortable.mount(new AutoScroll())

export default class extends Controller {
    static targets = []

    connect () {
        const bodyElement = this.element.getElementsByTagName('tbody')[0]
        if (!bodyElement) {
            throw new Error('No Body Element found!')
        }
        this.sortable = new Sortable(bodyElement, {
            handle: '.sortable-handle',
            onStart: () => {
                bodyElement.setAttribute('data-permanent', 'true')
            },
            onEnd: () => {
                const ids = []
                bodyElement.querySelectorAll('[data-sortable-item]').forEach((element) => {
                    ids.push(element.getAttribute('data-sortable-item'))
                })
                bodyElement.removeAttribute('data-permanent')
                $.ajax({
                    // processData: false,
                    enctype: 'multipart/form-data',
                    type: 'PUT',
                    url: this.element.getAttribute('data-sortable-action'),
                    data: {
                        authenticity_token: $('meta[name="csrf-token"]').attr('content'),
                        sort_order: ids.join(',')
                    }
                })
            }
        })
    }
}
