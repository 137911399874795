import { Controller } from 'stimulus'

export default class extends Controller {
    static targets = ['type', 'survey'];

    typeChange () {
        const selectedType = this.typeTarget.value
        if (selectedType !== 'survey') {
            this.surveyTarget.value = ''
        }
    }

    surveyChange () {
        const selectedSurvey = this.surveyTarget.value
        const selectedType = this.typeTarget.value
        if (selectedSurvey && selectedType !== 'survey') {
            this.typeTarget.value = ''
        }
    }
}
