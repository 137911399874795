import { Controller } from 'stimulus'

export default class extends Controller {
    connect () {
        this.element.addEventListener('turbo:submit-end', this.handleSuccess)
    }

    handleSuccess = (e) => {
        if (!e.detail.fetchResponse) {
            console.warn('No fetch response!!')
            return
        }
        const downloadUrl = e.detail.fetchResponse.header('download-url')
        if (downloadUrl) {
            this.download(downloadUrl)
        }
    }

    download = (url) => {
        const a = document.createElement('a')
        a.href = url
        a.download = url.split('/').pop()
        document.body.appendChild(a)
        a.click()
        document.body.removeChild(a)
    }

    disconnect () {
        this.element.addEventListener('turbo:submit-end', this.handleSuccess)
    }
}
